.switch-vertical {
    position: relative;
    display: inline-block;
    width: 24px;
    height: 60px;
  }
  
  .switch-vertical input[type="checkbox"] {
    display: none;
  }
  
  .slider-vertical {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 34px;
  }
  
  .slider-vertical:before {
    position: absolute;
    content: "";
    height: 22px;
    width: 20px;
    left: 10%;
    bottom: 5px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 50%;
  }
  
  input:checked + .slider-vertical {
    background-color: #2196F3;
  }
  
  input:focus + .slider-vertical {
    box-shadow: 0 0 1px #2196F3;
  }
  
  input:checked + .slider-vertical:before {
    left: 50%;
    -webkit-transform: translateY(-28px) translateX(-50%);
    -ms-transform: translateY(-28px) translateX(-50%);
    transform: translateY(-28px) translateX(-50%);
  }
  